import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Heading = styled(Typography)({
  fontWeight: 700,
  fontSize: "36px",
  textAlign: "center",
  color: "white",
});

const Paragraph = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  color: "white",
});

let AccordionData = [
  {
    summary: "What is Renq?",
    details:
      "Renq is a community-driven organization that was established to provide one stop solution for the traders to use cryptocurrency platform in decentralized manner.",
  },
  {
    summary: "What is Renq Token?",
    details:
      "$RENQ is governance token of Renq Finance, All future activities on Renq ecosystem will be done via $RENQ token.",
  },
  {
    summary: "Where Can i Buy Renq Token",
    details: (
      <Paragraph>
        Currently $RENQ is on presale stage and can be bought on Renq's website:{" "}
        <a
          href="https://renq.io"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "#EF4D84",
            borderBottom: "1px solid #EF4D84",
          }}
        >
          https://renq.io
        </a>
      </Paragraph>
    ),
  },
  {
    summary: "How Can I Buy Renq Token?",
    details: (
      <Box>
        To buy RENQ Tokens,
        <Paragraph mt={2}>Follow the below steps:</Paragraph>
        <Paragraph mt={1}>
          Step-1 Connect your Metamask or Trust Wallet Connect with{" "}
          <a
            href="https://renq.io"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#EF4D84",
              borderBottom: "1px solid #EF4D84",
            }}
          >
            https://renq.io
          </a>
        </Paragraph>
        <Paragraph>
          Step-2 Enter the amount you want to purchase and click on Buy Now.
        </Paragraph>
        <Paragraph>
          Step- 3 Your wallet provider will ask you to confirm the transactions
          1st confirmation is for the Gas Fee and 2nd confirmation will be for
          Token Purchase, you need to approve both.
        </Paragraph>
        <Paragraph>
          Step- 4 You have successfully bought RENQ Token's presale.
        </Paragraph>
      </Box>
    ),
  },
  {
    summary: "Where Will I Receive $RENQ Tokens?",
    details:
      "$RENQ tokens can be claimed once presale is finalized. In the same wallet that was used to buy RENQ Presale.",
  },
  {
    summary: "When Will I Receive $RENQ Tokens?",
    details: (
      <Paragraph>
        Users can claim $RENQ tokens from{" "}
        <a
          href="https://renq.io"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "#EF4D84",
            borderBottom: "1px solid #EF4D84",
          }}
        >
          https://renq.io
        </a>{" "}
        once presale is finalized.
      </Paragraph>
    ),
  },
  {
    summary: "Is There Any Vesting For RENQ Tokens?",
    details:
      "No, All bought tokens will be available for users to claim once presale is finalized.",
  },
  {
    summary: "What are presale stages?",
    details: (
      <Box>
        The Renq presale will occur in multiple stages, with the price of $RENQ
        tokens increasing after each stage's hard cap has been reached.
        <Paragraph>
          Learn More about Renq Presale Stages:{" "}
          <a
            href=" https://renq.io/presale"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#EF4D84",
              borderBottom: "1px solid #EF4D84",
            }}
          >
            https://renq.io/presale
          </a>
        </Paragraph>
      </Box>
    ),
  },
];
const Faqs = () => {
  const [expanded, setExpanded] = useState(false);
  // const check = useMediaQuery("(max-width:750px)")

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container>
      <Box py="50px">
        <Heading
          mb={2}
          sx={{ fontWeight: 700, fontSize: { md: "36px", xs: "25px" } }}
        >
          Frequently Asked Questions
        </Heading>
        <Paragraph
          textAlign={"center"}
          width={{ xs: "95%", md: "75%" }}
          m="auto"
        >
          If you have any questions about our website, Here are answers to the
          most frequently asked questions. If you cannot find your answer below,
          please contact us via the contact form or at{" "}
          <a
            href="mailto:support@renq.io"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#EF4D84",
              borderBottom: "1px solid #EF4D84",
            }}
          >
            support@renq.io
          </a>
        </Paragraph>

        <Box mt="50px">
          {AccordionData.map((value, index) => {
            return (
              <Box sx={{ margin: "auto", width: "100%" }} key={index}>
                <Accordion
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                  sx={{
                    marginTop: "20px",
                    background: "rgba(255, 255, 255, 0.05)",
                    border: "1px solid #312F55",
                    backdropFilter: "blur(8.5px)",
                    borderRadius: "6px",
                    mb: "10px",
                  }}
                >
                  <AccordionSummary
                    aria-controls={`panel${index + 1}bh-content`}
                    id={`panel${index + 1}bh-header`}
                    className="borderRad"
                    expandIcon={
                      <KeyboardArrowDownIcon
                        sx={{ color: "white", fontSize: "30px" }}
                      />
                    }
                    sx={{
                      padding: "0px 20px 0px 0px",
                      my: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        px="20px"
                        textAlign="left"
                        fontSize={{ md: "20px", sm: "16px" }}
                        lineHeight={"40px"}
                        sx={{
                          color: "white",
                          fontWeight: "700",
                        }}
                      >
                        {value.summary}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      paddingY: "10px",
                      marginTop: "-15px",
                    }}
                  >
                    <Box mt={2} textAlign="left">
                      <Box textAlign="left">{value.details}</Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default Faqs;
