import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
const ico = "/images/ico.png";
const image = "/images/image.png";

export default function ICO() {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <>
      <Box
        py={5}
        sx={{
          backgroundColor: "#050330",
        }}
        id="About"
      >
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={6}>
              <Box>
                <img src={ico} width="90%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                mt={{ xs: 0, md: 5 }}
                fontFamily="Open Sans"
                fontWeight="700"
                textAlign="left"
                fontSize={{ xs: "26px", md: "36px" }}
                color="#FFFFFF"
              >
                About Renq
              </Typography>
              <Typography
                mt={2}
                fontFamily="Open Sans"
                fontWeight="400"
                fontSize={{ xs: "13px", md: "14px" }}
                color="#FFFFFF"
              >
                Renq is a community-driven organization that is established to
                provide a one stop solution for all kind of traders under one
                platform in the DeFi world.
                <br /> We aim to empower our users with a comprehensive solution
                by providing them a platform that has benefits of a centralized
                exchange and beyond.
              </Typography>
              <Box ml={2} mt={4} display="flex">
                <img src={image} height={matches ? "450px" : "290px"} alt="" />
                <Box ml={3}>
                  <Typography
                    fontFamily="Open Sans"
                    fontWeight="700"
                    fontSize={{ xs: "20px", md: "20px" }}
                    color="#FFFFFF"
                  >
                    Multi Chain Dex
                  </Typography>
                  <Typography
                    fontFamily="Open Sans"
                    fontWeight="400"
                    fontSize={{ xs: "14px", md: "14px" }}
                    color="#FFFFFF"
                  >
                    Connecting all isolated blockchains and establish a
                    cross-chain asset exchange network, providing all necessary
                    underlying support for the Defi ecosystem.
                  </Typography>
                  <Typography
                    mt={4}
                    fontFamily="Open Sans"
                    fontWeight="700"
                    fontSize={{ xs: "20px", md: "20px" }}
                    color="#FFFFFF"
                  >
                    Never Ending Liquidity
                  </Typography>
                  <Typography
                    fontFamily="Open Sans"
                    fontWeight="400"
                    fontSize={{ xs: "14px", md: "14px" }}
                    color="#FFFFFF"
                  >
                    Renq obtains its liquidity from a variety of exchanges and
                    has the capacity to distribute a single trade transaction
                    across multiple DEXs in order to obtain the most competitive
                    prices.
                  </Typography>
                  <Typography
                    mt={4}
                    fontFamily="Open Sans"
                    fontWeight="700"
                    fontSize={{ xs: "20px", md: "20px" }}
                    color="#FFFFFF"
                  >
                    Community Driven
                  </Typography>
                  <Typography
                    fontFamily="Open Sans"
                    fontWeight="400"
                    fontSize={{ xs: "14px", md: "14px" }}
                    color="#FFFFFF"
                  >
                    Governed by its community, via forum discussions and, when
                    pertinent, voting on proposals by the RENQ Holders.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
