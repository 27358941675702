import React from "react";

import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

const IcoTokenImg = "/images/icoToken.png";
const IcoHeading = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 700,
  fontSize: "20px",
  color: "#FFFFFF",
});
const IcoText = styled(Box)({
  fontFamily: "Open Sans",
  fontSize: "14px",
  color: "#FFFFFF",
});
const IcoToken = () => {
  const matches = useMediaQuery("(max-width:700px)");
  const IcoArray = [
    {
      heading: "Token Name",
      text: "Renq Finance",
    },
    {
      heading: "Token Symbol",
      text: "RENQ",
    },
    {
      heading: "Total Supply",
      text: "1,000,000,000",
    },
    {
      heading: "Token Type",
      text: "Ethereum blockchain (ERC20)",
    },
    {
      heading: "Decimals",
      text: "18",
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#050330", py: 7 }}>
      <Container maxWidth="lg">
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontWeight: 700,
            fontSize: "36px",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          Renq Token
        </Typography>
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontSize: "14px",
            color: "#FFFFFF",
            textAlign: "center",
            width: { xs: "90%", md: "60%" },
            m: "auto",
            py: "0.5rem",
          }}
        >
          $RENQ is the governance token of Renq Finance. At inception $RENQ will
          be deployed on Ethereum blockchain (ERC20) and will be bridged through
          all the chains after launch.
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={6} md={4} my={2}>
            <Box
              sx={{
                border: "1px solid #ffffff",
                p: 2,
                borderRadius: "14px",
                background: "rgba(255, 255, 255, 0.15)",
              }}
            >
              <Box
                sx={{
                  p: 1,
                  background:
                    "linear-gradient(90.1deg, #4889DA 0.08%, #FB497F 101.84%)",
                  borderRadius: "6px",
                  my: "1rem",
                }}
              >
                <IcoHeading>$RENQ Token</IcoHeading>
                <IcoText>0xff8C479134A18918059493243943150776cF8CF2</IcoText>
              </Box>
              {IcoArray.map(({ heading, text }, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      p: 1,
                      background: "rgba(255, 255, 255, 0.05)",
                      borderRadius: "6px",
                      my: "1rem",
                    }}
                  >
                    <IcoHeading>{heading}</IcoHeading>
                    <IcoText>{text}</IcoText>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6.5}>
            <img
              src={IcoTokenImg}
              alt=""
              style={{ width: !matches ? "90%" : "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default IcoToken;
