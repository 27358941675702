import React from "react";
import Slider from "react-slick";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ArrowRight, ArrowLeft } from "@mui/icons-material";

import { teamArray } from "./Team";

const comonBtnStyling = {
  display: "flex",
  position: "absolute",
  zIndex: 1,
  cursor: "pointer",
  top: "24px",
};
const TeamNextArrow = ({ onClick }) => {
  const matchesMeida = useMediaQuery("(max-width: 700px)");

  return (
    <Box
      sx={{
        ...comonBtnStyling,
        right: !matchesMeida ? 510 : 110,
        top: !matchesMeida ? "100%" : "100%",

        transition: ".5s ease",
      }}
      onClick={onClick}
    >
      <ArrowRight
        sx={{ color: "#EF4D84", fontSize: { xs: "4rem", md: "6rem" } }}
      />
    </Box>
  );
};

const TeamPrevArrow = ({ onClick }) => {
  const matchesMeida = useMediaQuery("(max-width: 700px)");
  return (
    <Box
      sx={{
        ...comonBtnStyling,
        right: !matchesMeida ? 545 : 135,
        top: !matchesMeida ? "100%" : "100%",

        transition: ".5s ease",
      }}
      onClick={onClick}
    >
      <ArrowLeft
        sx={{ color: "#EF4D84", fontSize: { xs: "4rem", md: "6rem" } }}
      />
    </Box>
  );
};

const teamSettings = {
  nextArrow: <TeamNextArrow />,
  prevArrow: <TeamPrevArrow />,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },

    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TeamSlider = () => {
  return (
    <Box py="30px">
      <Slider {...teamSettings}>
        {teamArray.map(({ image, name, position, icon }, i) => {
          return (
            <Box key={i} my={1}>
              <Box height="100%" borderRadius="12px" overflow="hidden">
                <img src={image} alt="" width="100%" />
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box
                    sx={{
                      background: "rgba(16, 16, 16, 0.14)",
                      border: "1px solid rgba(212, 212, 212, 0.4)",
                      backdropFilter: "blur(8.5px)",
                      borderRadius: "6px",
                      p: 0.5,
                      width: "220px",
                      mt: -13,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      fontFamily="Open Sans"
                      fontSize="12px"
                      textAlign="center"
                      my={0.5}
                    >
                      {position}
                    </Typography>
                    <Box textAlign="center">{icon}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default TeamSlider;
