import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
const Graph1 = "/images/graph1.png";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: "25px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background:
      theme.palette.grey[theme.palette.mode === "#422B5D" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "25px",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(90deg, #2735FD, #50D1F8 15%, #C29632 40%, #2DB600 55%,#FD813D 75%, #FB3B78 85%,#2A7E90)"
        : "linear-gradient(90deg, #2735FD, #50D1F8 15%, #C29632 40%, #2DB600 55%,#FD813D 75%, #FB3B78 85%,#2A7E90)",
  },
}));

const Tokenomics = () => {
  const Tokentext = styled(Typography)({
    fontFamily: "Prompt",
    fontWeight: 700,
    fontSize: "20px",
    color: "#FFFFFF",
  });
  const Progresstext = styled(Typography)({
    fontFamily: "Prompt",
    fontSize: "14px",
    color: "#FFFFFF",
  });
  return (
    <Box backgroundColor="#1D0742" py={4}>
      <Container maxWidth="lg">
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontWeight: 700,
            fontSize: { xs: "26px", md: "36px" },
            color: "#FFFFFF",
            textAlign: "center",
            mb: "2rem",
          }}
        >
          RENQ Tokenomics
        </Typography>

        <Grid
          container
          spacing={5}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={6} md={4.5}>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                TOTAL TOKENS
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>1,000,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  1 Billion
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={100} />
            </Box>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                PUBLIC SALE
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>500,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  50%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={50} />
            </Box>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                RENQ POOL
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>100,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  10%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={10} />
            </Box>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                RENQ VAULT
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>100,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  10%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={10} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={Graph1} alt="" width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4.5}>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                RENQ FARMS
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>100,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  10%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={10} />
            </Box>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                EXCHANGE LISTINGS
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>100,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  10%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={10} />
            </Box>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                LIQUIDITY
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>50,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  5%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={5} />
            </Box>
            <Box my={5}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#25B8BD",
                }}
              >
                TEAM
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tokentext>50,000,000</Tokentext>
                <Progresstext fontWeight={400} fontSize="14px">
                  5%
                </Progresstext>
              </Box>
              <BorderLinearProgress variant="determinate" value={5} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Tokenomics;
