import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";

import TeamSlider from "./TeamSlider";

const Team1 = "/images/assests/Team1.jpg";
const Team2 = "/images/assests/Team2.jpg";
const Team3 = "/images/assests/Team3.jpg";
const Team4 = "/images/assests/Team4.jpg";
const Team5 = "/images/assests/Team5.jpg";
const Team6 = "/images/assests/Team6.jpg";
const Team7 = "/images/assests/Team7.jpg";

const iconStyle = {
  color: "#fff",
  transition: "0.4s",
  cursor: "pointer",
  "&:hover": {
    color: "#EF4D84",
  },
};

export const teamArray = [
  {
    image: Team1,
    name: "James Bladen",
    position: "CPO",
    icon: (
      <a href="https://t.me/jamiebladen" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },
  {
    image: Team2,
    name: "Nathan Hudson",
    position: "Marketing Lead",
    icon: (
      <a href="https://t.me/hudson121" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },
  {
    image: Team3,
    name: "Samar",
    position: "Lead Strategist",
    icon: (
      <a href="https://t.me/renqsamar" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },
  {
    image: Team4,
    name: "Johan van Asseldonk",
    position: "Development Lead",
    icon: (
      <a href="https://t.me" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },

  {
    image: Team5,
    name: "Markus Trafford",
    position: "Senior Backend",
    icon: (
      <a href="https://t.me" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },

  {
    image: Team6,
    name: "Hiroyoshi Nozawa",
    position: "Web3 Engineer",
    icon: (
      <a href="https://t.me" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },

  {
    image: Team7,
    name: "Zachery Salter",
    position: "Blockchain Expert",
    icon: (
      <a href="https://t.me" target="_blank" rel="noreferrer">
        <TelegramIcon sx={iconStyle} />
      </a>
    ),
  },
];

const Team = () => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Box backgroundColor="#100C3D" py={4} id="Team">
      <Container maxWidth="lg">
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontWeight: 700,
            fontSize: "36px",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          TEAM
        </Typography>
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontSize: "14px",
            color: "#FFFFFF",
            textAlign: "center",
            width: { xs: "90%", md: "70%" },
            m: "auto",
            pt: "0.5rem",
            pb: "2rem",
          }}
        >
          The team behind RENQ is comprised of blockchain network specialists,
          technologists, entrepreneurs, and engineers who come from a wide
          variety of backgrounds and possess a wide range of skills.
        </Typography>
        {matches ? (
          <TeamSlider />
        ) : (
          <Grid container justifyContent="center" spacing={1.8}>
            {teamArray.map(({ image, name, position, icon }, i) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={i} my={1}>
                  <Box height="100%" borderRadius="12px" overflow="hidden">
                    <img src={image} alt="" width="100%" height="100%" />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        sx={{
                          background: "rgba(16, 16, 16, 0.14)",
                          border: "1px solid rgba(212, 212, 212, 0.4)",
                          backdropFilter: "blur(8.5px)",
                          borderRadius: "6px",
                          p: 0.5,
                          width: "220px",
                          mt: -14,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#FFFFFF",
                            textAlign: "center",
                          }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          fontFamily="Open Sans"
                          fontSize="12px"
                          textAlign="center"
                          my={0.5}
                        >
                          {position}
                        </Typography>
                        <Box textAlign="center">{icon}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Team;
