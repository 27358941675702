import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";

const m1 = "/images/m1.png";
const m2 = "/images/m2.png";
const m3 = "/images/m3.png";
const m4 = "/images/m4.png";
const m5 = "/images/cointelegraph.png";
const m6 = "/images/certik.png";
const m7 = "/images/bitcoin.com.png";
const m8 = "/images/coinmarketcap.png";
const m9 = "/images/coingecko.png";
const m10 = "/images/bitcoinist.webp";
const m11 = "/images/newsbtc.png";
const m12 = "/images/crypto-news.svg";
const m13 = "/images/analytics-insight.png";
const m14 = "/images/amb-crypto.png";
const m15 = "/images/cryptoslate.png";
const m16 = "/images/crypto-insider.webp";
const m17 = "/images/blockzeit.png";
const m18 = "/images/bitcoinbazis.jpeg";
const m19 = "/images/coinpedia.webp";
const m20 = "/images/crypto-basic.png";
const m21 = "/images/crypto-news-flash-logo.png";
const m22 = "/images/logo-blockchainmedia.png";

export default function Partners() {
  return (
    <>
      <Box
        py={2}
        sx={{
          backgroundColor: "#13073D",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            textAlign="center"
            fontFamily="Open Sans"
            fontWeight="700"
            fontSize={{ xs: "26px", md: "36px" }}
            color="#FFFFFF"
          >
            As seen in
          </Typography>
          <Marquee
            speed={50}
            pauseOnClick={true}
            pauseOnHover={true}
            gradient={false}
            // gradientColor={[255, 255, 255]}
            // gradientWidth={50}
            // style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <Box
              my={3}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              {/* <img src={m1} style={{ marginRight: "100px" }} alt="" /> */}

              {/* <img src={m2} style={{ marginRight: "100px" }} alt="" /> */}

              <img src={m3} style={{ marginRight: "100px" }} alt="" />

              <img src={m4} style={{ marginRight: "100px" }} alt="" />

              <img
                src={m5}
                style={{ marginRight: "100px", height: "40px" }}
                alt=""
              />

              <img
                src={m6}
                style={{
                  marginRight: "100px",
                  height: "40px",
                  filter: "invert(100%)",
                }}
                alt=""
              />

              <img
                src={m7}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />

              <img
                src={m8}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "40px",
                }}
                alt=""
              />

              <img
                src={m9}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "40px",
                }}
                alt=""
              />
              <img
                src={m10}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m11}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m12}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m13}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m14}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m15}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "40px",
                }}
                alt=""
              />
              <img
                src={m16}
                style={{
                  marginRight: "100px",
                  filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m17}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m18}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m19}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "20px",
                }}
                alt=""
              />
              <img
                src={m20}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "40px",
                }}
                alt=""
              />
              <img
                src={m21}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "40px",
                }}
                alt=""
              />
              <img
                src={m22}
                style={{
                  marginRight: "100px",
                  // filter: "invert(100%)",
                  height: "40px",
                }}
                alt=""
              />
            </Box>
          </Marquee>
        </Container>
      </Box>
    </>
  );
}
