import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

const bg = "/images/bg.png";
const t1 = "/images/t1.png";
const t2 = "/images/t2.png";
const t3 = "/images/t3.png";
const t4 = "/images/t4.png";
const t5 = "/images/t5.png";
const t6 = "/images/t6.png";
const t7 = "/images/t7.png";
const t8 = "/images/t8.png";

export default function Tokens() {
  const tokenData = [
    {
      img: t1,
      head: "Wallet",
      text: "Multi Chain All-in-one solution for all of your trading requirements.",
    },
    {
      img: t2,
      head: "Exchange",
      text: "Trade your favorite crypto assets across all chains.",
    },
    {
      img: t3,
      head: "Aggregator",
      text: "Instantly analyzes thousands of quotes and fees across multiple DEXes to provide users with the best rates.",
    },
    {
      img: t4,
      head: "Perpetuals Futures",
      text: "Long or short, high leverage traders can trade with up to 100x leverage.",
    },

    {
      img: t6,
      head: "Vault",
      text: "Multichain Yield Optimizer that allows its users to earn compound interest on their crypto holdings.",
    },
    {
      img: t7,
      head: "Lending Protocol",
      text: "Lend and borrow, with pools of assets and floating interest rates algorithmically determined by the supply and demand for the asset.",
    },
    {
      img: t8,
      head: "DeFi & NFT Launchpad",
      text: "Get early access and take part in DeFi & NFT projects across all chains.",
    },
    {
      img: t5,
      head: "Forums (DAO Governance)",
      text: "Propose, discuss and vote on RENQ's future endeavours.",
    },
  ];
  return (
    <>
      <Box
        py={4}
        sx={{
          background: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        id="Ecosystem"
      >
        <Container maxWidth="lg">
          <Typography
            textAlign="center"
            mt={{ xs: 0, md: 8 }}
            fontFamily="Open Sans"
            fontWeight="700"
            fontSize={{ xs: "26px", md: "36px" }}
            color="#FFFFFF"
          >
            ECOSYSTEM
          </Typography>
          <Typography
            margin="auto"
            textAlign="center"
            width={{ xs: "90%", md: "60%" }}
            fontSize={{ xs: "13px", md: "14px" }}
            fontWeight="400"
            fontFamily="Open Sans"
            color="#FFFFFF"
          >
            Renq, an innovative multi chain non-custodial decentralized
            exchange, elevates decentralized trading by providing users with a
            direct trading option through renq's wallet app.
          </Typography>
          <Grid container spacing={3} my={5}>
            {tokenData.map(({ img, head, text }, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  md={3}
                  key={i}
                  mt={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src={img} width="72px" height="72px" alt="" />
                  <Typography
                    mt={3}
                    textAlign="center"
                    fontFamily="Open Sans"
                    fontWeight="700"
                    fontSize={{ xs: "18px", md: "20px" }}
                    color="#FFFFFF"
                  >
                    {head}
                  </Typography>
                  <Typography
                    mt={1}
                    textAlign="center"
                    fontFamily="Open Sans"
                    fontWeight="400"
                    fontSize={{ xs: "13px", md: "14px" }}
                    color="#FFFFFF"
                  >
                    {text}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
