import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";

// import UserContext from "../components/UserContext";
import Home_1 from "./Home.jsx/Home";
import Partners from "../components/Partners";
import ICO from "../components/ICO";
import Documents from "../components/Documents";
import Tokens from "../components/Tokens";
import IcoToken from "../components/IcoToken/IcoToken";
import Tokenomics from "../components/Tokenomics/Tokenomics";
import Team from "../components/Team/Team";
import Faqs from "../components/Faqs/Faqs";
import axios from "axios";

export default function Home() {
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    try {
      axios
        .get("https://profound-smakager-f042ee.netlify.app/api/presale", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          const data = response.data;
          console.log("data is", data);
          setData(data);
        });
    } catch (err) {
      // err.statusCode = 404;
      console.log(err);
      return {};
    }
  }

  //   getData();

  return (
    <>
      <Home_1 data={data} />
      <Partners />
      <ICO />
      <Documents />
      <Tokens />
      {/* <Comparsion /> */}
      <IcoToken />
      <Tokenomics />
      <Team />
      <Faqs />
    </>
  );
}

// export async function getServerSideProps() {
//   const data = await getData();
//   return {
//     props: { data },
//   };
// }
